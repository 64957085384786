.bg {
    background-image: url('../../images/bg.jpg');
    background-size: cover;
    background-position: center center;
    width: 100%;
    min-height: 100vh;
    background-attachment: fixed;
    /* position: relative; */
    top: 0;
    left: 0;
}